import React, {useEffect, useReducer, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope, faEye, faPhone, faSignOutAlt, faUnlockAlt, faUser, faWallet} from '@fortawesome/free-solid-svg-icons';
import {faUserCircle} from '@fortawesome/free-regular-svg-icons';
import {Alert, Button, Card, Col, Container, Dropdown, Form, Image, InputGroup, Modal, Nav, Navbar, OverlayTrigger, Row, Tooltip} from '@themesberg/react-bootstrap';

import Profile from '../assets/img/profile.png';
import Utils from '../services/Utils';
import {Link, useHistory} from 'react-router-dom';
import {EMAIL, LANGUAGE, NAME, PHONE} from '../services/constants';
import {FormattedMessage} from 'react-intl';
import API from '../services/API';


export default (props) => {

  const formReducer = (state, event) => {
    if (event.value !== undefined) {
      return {
        ...state,
        [event.name]: event.value
      }
    } else {
      return event;
    }
  }

  const {intl} = props;
  const history = useHistory();
  const focus = ['focus'];
  const [accountFormData, setAccountFormData] = useReducer(formReducer, {});
  const [welcomeFormData, setWelcomeFormData] = useReducer(formReducer, {});
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showSubscriptionAlert, setShowSubscriptionAlert] = React.useState(false);
  const [language, setLanguage] = React.useState(Utils.getLanguage());
  const passwordInputType = showPassword ? 'text' : 'password';
  const passwordIconColor = showPassword ? '#262B40' : '';
  const name = localStorage.getItem(NAME);
  const email = localStorage.getItem(EMAIL);
  const phone = localStorage.getItem(PHONE);

  useEffect(() => {
    if (!Utils.isAccessToken(history, true)) {
      return;
    }
    if (name === 'Société de transport') {
      setShowWelcomeModal(true);
    }
    API.getCheckSubscription(history).then(response => {
      if (response && response.data.success === true) {
        if (response.data.data && (new Date().getTime() + 86_400_000) > new Date(response.data.data).getTime()) {
          setShowSubscriptionAlert(true);
        }
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'UNABLE_TO_CHECK_YOUR_SUBSCRIPTION'}));
      }
    });
  }, []);

  const handleLogout = (e) => {
    Utils.logout(history);
  }

  const handleClickMyAccount = (e) => {
    setAccountFormData({});
    setAccountFormData({
      name: 'companyName',
      value: name
    });
    setAccountFormData({
      name: 'phone',
      value: phone
    });
    setShowAccountModal(true);
  }

  const handleClickMySubscription = (e) => {
    API.getStripePortalUrl(history).then(response => {
      if (response && response.data.success === true) {
        window.location.replace(response.data.data);
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  const handleClickRenewMySubscription = (e) => {
    API.getStripeRenewSubscriptionUrl(history).then(response => {
      if (response && response.data.success === true) {
        window.location.replace(response.data.data);
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  const handleChangeAccount = event => {
    setAccountFormData({
      name: event.target.id,
      value: event.target.value
    });
  }

  const handleChangeWelcomeForm = event => {
    setWelcomeFormData({
      name: event.target.id,
      value: event.target.value
    });
  }

  const handleSaveAccount = (e) => {
    e.preventDefault();
    API.postProfile(history, accountFormData).then(response => {
      if (response && response.data.success === true) {
        Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'SAVED_CHANGES'}));
        localStorage.setItem(NAME, accountFormData.companyName);
        localStorage.setItem(PHONE, accountFormData.phone);
        setShowAccountModal(false);
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    })
      .catch(error => {
        if (error.response.status === 401) {
          Utils.notifyWarning(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'WRONG_CURRENT_PASSWORD'}));
        } else {
          Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
        }
      });
  }

  const handleSaveWelcomeForm = (e) => {
    e.preventDefault();
    API.postNewProfile(history, welcomeFormData).then(response => {
      if (response && response.data.success === true) {
        Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'SAVED_CHANGES'}));
        localStorage.setItem(NAME, welcomeFormData.companyName);
        setShowWelcomeModal(false);
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  const handleChangeLanguage = event => {
    setLanguage(event.target.value);
    localStorage.setItem(LANGUAGE, event.target.value);
    API.getLanguage(history, event.target.value).then(response => {
      window.location.reload();
    });
  }

  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
      <Container fluid className="px-0">
          <div className="d-flex align-items-center">
            <Form>
              <Form.Group id="defaultLanguageSelect">
                <Form.Select id="language" onChange={handleChangeLanguage} value={language} defaultValue={language}>
                  <option value="fr">&#x1f1eb;&#x1f1f7;&nbsp;</option>
                  <option value="en">&#x1f1ec;&#x1f1e7;&nbsp;</option>
                </Form.Select>
              </Form.Group>
            </Form>
          </div>
        {showSubscriptionAlert && <Alert variant="danger">
          <FormattedMessage id="SUBSCRIPTION_ALERT" values={{
            clickHere: <Card.Link onClick={handleClickRenewMySubscription} className="fw-bold">
              <FormattedMessage id="CLICK_HERE"/>
            </Card.Link>
          }}/>
        </Alert>}
        <div className={`d-flex justify-content-between`}>
          <Nav className="align-items-center">
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <Image src={Profile} className="user-avatar md-avatar rounded-circle"/>
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold">{name}</span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                <Dropdown.Item className="fw-bold" onClick={handleClickMyAccount}>
                  <FontAwesomeIcon icon={faUserCircle} className="me-2"/> <FormattedMessage id="MY_ACCOUNT"/>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold" onClick={handleClickMySubscription}>
                  <FontAwesomeIcon icon={faWallet} className="me-2"/> <FormattedMessage id="MY_SUBSCRIPTION"/>
                </Dropdown.Item>

                <Dropdown.Divider/>

                <Dropdown.Item className="fw-bold" onClick={handleLogout}>
                  <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2"/> <FormattedMessage id="LOGOUT"/>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
      <Modal as={Modal.Dialog} centered show={showAccountModal} onHide={() => setShowAccountModal(false)}>
        <Modal.Header>
          <Modal.Title className="h6"><FormattedMessage id="MY_ACCOUNT"/></Modal.Title>
          <Button variant="close" aria-label="Close" onClick={() => setShowAccountModal(false)}/>
        </Modal.Header>
        <Modal.Body>
          <Form id="account-form" onSubmit={handleSaveAccount}>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label><FormattedMessage id="BUSINESS_NAME"/></Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faUser}/>
                    </InputGroup.Text>
                    <OverlayTrigger trigger={focus}
                                    overlay={
                                      <Tooltip><FormattedMessage id="TOOLTIP_COMPANY_NAME"/></Tooltip>
                                    }>
                    <Form.Control required type="text" id="companyName"
                                  onChange={handleChangeAccount} value={accountFormData.companyName}/>
                    </OverlayTrigger>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label><FormattedMessage id="EMAIL_ADDRESS"/></Form.Label>
                  <InputGroup>
                    <InputGroup.Text style={{backgroundColor: '#F5F8FB'}}>
                      <FontAwesomeIcon icon={faEnvelope}/>
                    </InputGroup.Text>
                    <Form.Control disabled type="email" id="email"
                                  onChange={handleChangeAccount} value={email}/>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <br/>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label><FormattedMessage id="PHONE"/></Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faPhone}/>
                    </InputGroup.Text>
                    <OverlayTrigger trigger={focus}
                                    overlay={
                                      <Tooltip><FormattedMessage id="TOOLTIP_PHONE"/></Tooltip>
                                    }>
                      <Form.Control type="tel" id="phone"
                                    onChange={handleChangeAccount} value={accountFormData.phone}/>
                    </OverlayTrigger>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <br/>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label><FormattedMessage id="NEW_PASSWORD"/></Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faUnlockAlt}/>
                    </InputGroup.Text>
                    <OverlayTrigger trigger={focus}
                                    overlay={
                                      <Tooltip><FormattedMessage id="TOOLTIP_PASSWORD"/></Tooltip>
                                    }>
                      <Form.Control type="password" id="newPassword"
                                    onChange={handleChangeAccount} value={accountFormData.newPassword}/>
                    </OverlayTrigger>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label><FormattedMessage id="CURRENT_PASSWORD"/></Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faUnlockAlt}/>
                    </InputGroup.Text>
                    <OverlayTrigger trigger={focus}
                                    overlay={
                                      <Tooltip><FormattedMessage id="TOOLTIP_CURRENT_PASSWORD"/></Tooltip>
                                    }>
                      <Form.Control required type="password" id="currentPassword"
                                    onChange={handleChangeAccount} value={accountFormData.currentPassword}/>
                    </OverlayTrigger>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <br/>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" type="submit" form="account-form">
            <FormattedMessage id="UPDATE"/>
          </Button>
          <Button variant="link" className="text-gray ms-auto" onClick={() => setShowAccountModal(false)}>
            <FormattedMessage id="CLOSE"/>
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal as={Modal.Dialog} centered show={showWelcomeModal} onHide={() => {
      }}>
        <Modal.Header>
          <Modal.Title className="h6"><FormattedMessage id="WELCOME"/></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <FormattedMessage id="THANK_YOU_BEFORE_STARTING"/>
            <br/><br/>
            <Button variant="light" onClick={handleClickMySubscription}>
              <FormattedMessage id="UPDATE_MY_BILLING_INFORMATION"/>
            </Button>
          </div>
          <br/>
          <div className="text-center">
            <FormattedMessage id="TELL_US_COMPANY_AND_PASSWORD"/>
          </div>
          <br/>
          <Form id="welcome-form" onSubmit={handleSaveWelcomeForm}>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label><FormattedMessage id="BUSINESS_NAME"/></Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faUser}/>
                    </InputGroup.Text>
                    <Form.Control required type="text" id="companyName"
                                  onChange={handleChangeWelcomeForm} value={welcomeFormData.companyName}/>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label><FormattedMessage id="NEW_PASSWORD"/></Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faUnlockAlt}/>
                    </InputGroup.Text>
                    <Form.Control required type={passwordInputType} id="newPassword"
                                  minLength="8"
                                  onChange={handleChangeWelcomeForm} value={welcomeFormData.newPassword}/>
                    <InputGroup.Text onClick={() => setShowPassword(!showPassword)} style={{borderRight: '1px solid #D1D7E0'}}>
                      <FontAwesomeIcon color={passwordIconColor} icon={faEye}/>
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" type="submit" form="welcome-form">
            <FormattedMessage id="I_FINISHED"/>
          </Button>
        </Modal.Footer>
      </Modal>
    </Navbar>
  );
};
