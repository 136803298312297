export default {
  tokenExpired: () => {
    return 'TOKEN_EXPIRED';
  },
  lastVehicleMustNotBeDisabled: () => {
    return 'LAST_VEHICLE_MUST_NOT_BE_DISABLED';
  },
  surchargeCannotBeEnabledWithoutVehicule: () => {
    return 'SURCHARGE_CANNOT_BE_ENABLED_WITHOUT_VEHICLE';
  },
  packageCannotBeEnabledWithoutVehicule: () => {
    return 'PACKAGE_CANNOT_BE_ENABLED_WITHOUT_VEHICLE';
  },
  optionCannotBeEnabledWithoutVehicule: () => {
    return 'CUSTOM_OPTION_CANNOT_BE_ENABLED_WITHOUT_VEHICLE';
  },
  discountCodeCannotBeEnabledWithoutVehicule: () => {
    return 'DISCOUNT_CODE_CANNOT_BE_ENABLED_WITHOUT_VEHICLE';
  },
  discountCodeAlreadyExists: () => {
    return 'DISCOUNT_CODE_ALREADY_EXISTS';
  },
  discountCodeInvalidDates: () => {
    return 'DISCOUNT_CODE_INVALID_DATES';
  },
  discountCodeWithoutDiscount: () => {
    return 'DISCOUNT_CODE_WITHOUT_DISCOUNT';
  },
  stripeAccountDoesNotExist: () => {
    return 'STRIPE_ACCOUNT_DOES_NOT_EXIST';
  },
  bookingAlreadyConfirmed: () => {
    return 'BOOKING_ALREADY_CONFIRMED';
  },
  bookingAlreadyCanceled: () => {
    return 'BOOKING_ALREADY_CANCELED';
  },
  googleApiDisabled: () => {
    return 'GOOGLE_API_DISABLED';
  },
  googleApiWarning: () => {
    return 'GOOGLE_API_WARNING';
  },
};