import React, {useEffect, useReducer} from 'react';
import {Button, ButtonGroup, Card, Col, Dropdown, Form, InputGroup, Modal, Nav, Pagination, Row, Tab, Table} from '@themesberg/react-bootstrap';
import Utils from '../services/Utils';
import {useHistory} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {FormattedMessage} from 'react-intl';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faCheck,
  faChevronDown,
  faCircleXmark,
  faClipboard,
  faEllipsisH,
  faEnvelope,
  faEye,
  faFileCsv,
  faShare,
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import API from '../services/API';

export default (props) => {
  Utils.setBackground(false);

  const formReducer = (state, event) => {
    if (event.value !== undefined) {
      return {
        ...state,
        [event.name]: event.value
      }
    } else {
      return event;
    }
  }

  const {intl} = props;
  const history = useHistory();
  const [bookings, setBookings] = React.useState([]);
  const [activeItem, setActiveItem] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [showCancelModal, setShowCancelModal] = React.useState(false);
  const [showDetailsModal, setShowDetailsModal] = React.useState(false);
  const [showShareModal, setShowShareModal] = React.useState(false);
  const [currentBookingId, setCurrentBookingId] = React.useState(false);
  const [confirmMessage, setConfirmMessage] = React.useState('');
  const [cancelMessage, setCancelMessage] = React.useState('');
  const [shareFormData, setShareFormData] = useReducer(formReducer, {});

  const handleChange = event => {
    setShareFormData({
      name: event.target.id,
      value: event.target.value
    });
  }

  const handleConfirmBookingButton = (id) => {
    setCurrentBookingId(id);
    setConfirmMessage('');
    setShowConfirmModal(true);
  }

  const handleCancelBookingButton = (id) => {
    setCurrentBookingId(id);
    setCancelMessage('');
    setShowCancelModal(true);
  }

  const handleDeleteBookingButton = (id) => {
    setCurrentBookingId(id);
    setShowDeleteModal(true);
  }

  const handleDetailsBookingButton = (id) => {
    setCurrentBookingId(id);
    setShowDetailsModal(true);
  }

  const handleShareBookingButton = (id) => {
    setShareFormData({});
    setCurrentBookingId(id);
    setShowShareModal(true);
  }

  const handleConfirmBooking = () => {
    API.postConfirmAdminBooking(history, currentBookingId, confirmMessage ? confirmMessage : ' ').then(response => {
      if (response && response.data.success === true) {
        Utils.notifySuccess(intl.formatMessage({id: 'BOOKING_CONFIRMED'}), intl.formatMessage({id: 'CUSTOMER_NOTIFIED_BY_EMAIL'}));
        setShowConfirmModal(false);
        refreshBookings(activeItem);
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  const handleCancelBooking = () => {
    API.postCancelAdminBooking(history, currentBookingId, cancelMessage ? cancelMessage : ' ').then(response => {
      if (response && response.data.success === true) {
        Utils.notifySuccess(intl.formatMessage({id: 'BOOKING_CANCELED'}), intl.formatMessage({id: 'CUSTOMER_NOTIFIED_BY_EMAIL'}));
        setShowCancelModal(false);
        refreshBookings(activeItem);
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  const handleShareBooking = (e) => {
    e.preventDefault();
    API.postShareAdminBooking(history, currentBookingId, shareFormData).then(response => {
      if (response && response.data.success === true) {
        Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'SENT_TO_YOUR_RECIPIENT'}));
        setShowShareModal(false);
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  const handleDeleteBooking = () => {
    API.deleteAdminBooking(history, currentBookingId).then(response => {
      if (response && response.data.success === true) {
        Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'BOOKING_DELETED'}));
        setShowDeleteModal(false);
        refreshBookings(activeItem);
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  const handleCsvReport = () => {
    API.getBookingCsvReport(history).then(response => {
      if (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Bookings.csv');
        document.body.appendChild(link);
        link.click();
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  const TableRow = (props) => {
    const {customer, id, price, pickUpTime, returnPickUpTime, vehicle, status, payment, paymentMode, orderDate, currency, discount} = props;
    const statusVariant = status === 'CONFIRMED' ? 'success'
      : status === 'VALIDATED' ? 'warning'
        : status === 'EXPIRED' ? 'danger'
          : '';
    const paymentVariant = payment === 'PAID' ? 'success'
      : payment === 'FAILED' ? 'danger'
        : '';
    const statusText = status === 'CONFIRMED' ? intl.formatMessage({id: 'CONFIRMED'})
      : status === 'CANCELED' ? intl.formatMessage({id: 'CANCELED'})
        : status === 'VALIDATED' ? intl.formatMessage({id: 'WAITING_CONFIRMATION'})
          : status === 'EXPIRED' ? intl.formatMessage({id: 'EXPIRED'})
            : '';
    const paymentText = payment === 'PAID' ? intl.formatMessage({id: 'PAID'})
      : payment === 'FAILED' ? intl.formatMessage({id: 'PAYMENT_FAILED'})
        : payment === 'PENDING' ? intl.formatMessage({id: 'ONLINE_PAYMENT_IN_PROGRESS'})
          : '';

    return (
      <tr>
        <td className="td-center">{id}</td>
        <td className="fw-bold td-center">
          {customer.firstName + ' ' + customer.lastName}
        </td>
        <td className="td-center">{vehicle}</td>
        <td className="td-center">{pickUpTime}{returnPickUpTime && (<span><br/>{returnPickUpTime}</span>)}</td>
        <td className="td-center">{new Intl.NumberFormat(navigator.language, {style: 'currency', currency: currency}).format(discount ? discount.discountPrice : price)}</td>
        <td className="td-center"><span className={`text-${statusVariant}`}>{statusText}</span></td>
        <td className="td-center">
          {paymentMode === 'ONLINE' && <span className={`text-${paymentVariant}`}>{paymentText}</span>}
          {paymentMode === 'DRIVER' && <FormattedMessage id="DRIVER_PAYMENT"/>}
        </td>
        <td className="td-center">{orderDate}</td>
        <td className="td-center">
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark"/>
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="custom-action-dropdown">
              <Dropdown.Item onSelect={() => handleDetailsBookingButton(id)}>
                <FontAwesomeIcon icon={faEye} className="me-2"/> <FormattedMessage id="VIEW_DETAILS"/>
              </Dropdown.Item>
              {status === 'VALIDATED' &&
                <Dropdown.Item className="text-success" onSelect={() => handleConfirmBookingButton(id)}>
                  <FontAwesomeIcon icon={faCheck} className="me-2"/> <FormattedMessage id="CONFIRM"/>
                </Dropdown.Item>
              }
              {status === 'VALIDATED' &&
                <Dropdown.Item className="text-warning" onSelect={() => handleCancelBookingButton(id)}>
                  <FontAwesomeIcon icon={faCircleXmark} className="me-2"/> <FormattedMessage id="CANCEL"/>
                </Dropdown.Item>
              }
              <Dropdown.Item className="text-info" onSelect={() => handleShareBookingButton(id)}>
                <FontAwesomeIcon icon={faShare} className="me-2"/> <FormattedMessage id="SHARE"/>
              </Dropdown.Item>
              <Dropdown.Item className="text-danger" onSelect={() => handleDeleteBookingButton(id)}>
                <FontAwesomeIcon icon={faTrashAlt} className="me-2"/> <FormattedMessage id="DELETE"/>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  const onPrevItem = () => {
    const prevActiveItem = activeItem === 1 ? activeItem : activeItem - 1;
    if (activeItem !== prevActiveItem) {
      setActiveItem(prevActiveItem);
      refreshBookings(prevActiveItem);
    }
  };

  const onNextItem = (totalPages) => {
    const nextActiveItem = activeItem === totalPages ? activeItem : activeItem + 1;
    if (activeItem !== nextActiveItem) {
      setActiveItem(nextActiveItem);
      refreshBookings(nextActiveItem);
    }
  };

  const items = [];
  let previousDots = false;
  let nextDots = false;
  for (let number = 1; number <= totalPages; number++) {
    const isItemActive = activeItem === number;

    const handlePaginationChange = () => {
      setActiveItem(number);
      refreshBookings(number);
    };

    if (totalPages <= 5) {
      items.push(
        <Pagination.Item active={isItemActive} key={number} onClick={handlePaginationChange}>
          {number}
        </Pagination.Item>
      );
    } else {
      if (isItemActive || ((activeItem + 3) >= number && number > activeItem - 3) || ((activeItem - 3) <= number && number < activeItem + 3)) {
        items.push(
          <Pagination.Item active={isItemActive} key={number} onClick={handlePaginationChange}>
            {number}
          </Pagination.Item>
        );
      } else if (!previousDots && number < activeItem) {
        previousDots = true;
        items.push(
          <Pagination.Item active={isItemActive} key="previousDots">
            ...
          </Pagination.Item>
        );
      } else if (!nextDots && number > activeItem) {
        nextDots = true;
        items.push(
          <Pagination.Item active={isItemActive} key="nextDots">
            ...
          </Pagination.Item>
        );
      }
    }
  }

  const refreshBookings = (page) => {
    API.getAdminBookings(history, page).then(response => {
      if (response && response.data.success === true) {
        setBookings(response.data.data.bookings);
        setTotalPages(response.data.data.pagination.totalPages)
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  useEffect(() => {
    if (!Utils.isAccessToken(history)) {
      return;
    }
    refreshBookings(1);
  }, []);

  const getFormattedCustomerAddress = (customer) => {
    let address = customer.address + ', ';

    if (customer.addressComplement) {
      address += customer.addressComplement + ', ';
    }

    address += customer.postalCode + ' ' + customer.city + ', ' + customer.country;
    return address;
  }

  const renderBookingDetails = () => {
    let formatter;
    let booking;
    if (bookings) {
      booking = bookings.find(b => b.id === currentBookingId);
      formatter = booking ? new Intl.NumberFormat(navigator.language, {style: 'currency', currency: booking.currency}) :
        new Intl.NumberFormat(navigator.language, {style: 'currency', currency: 'EUR'})
    }

    return <Tab.Container defaultActiveKey="journey">
      <Nav fill variant="pills" className="flex-column flex-sm-row">
        <Nav.Item style={{marginLeft: '10px'}}>
          <Nav.Link eventKey="journey" className="mb-sm-3 mb-md-0">
            <FormattedMessage id="JOURNEY"/>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item style={{marginRight: '10px'}}>
          <Nav.Link eventKey="customer" className="mb-sm-3 mb-md-0">
            <FormattedMessage id="CUSTOMER"/>
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <Tab.Content>
        <Tab.Pane eventKey="journey" className="py-4">
          {booking && <div>
            <b><FormattedMessage id="STARTING_ADDRESS"/>:</b> {booking.startingAddress}<br/>
            <b><FormattedMessage id="ARRIVAL_ADDRESS"/>:</b> {booking.arrivalAddress}<br/>
            <b><FormattedMessage id="VEHICLE"/>:</b> {booking.vehicle}<br/>
            <b><FormattedMessage id="PASSENGERS"/>:</b> {booking.passengers}<br/>
            <b><FormattedMessage id="BAGGAGE"/>:</b> {booking.baggage}<br/>
            <b><FormattedMessage
              id="ONE_WAY_OR_ROUND_TRIP"/>:</b> {booking.returnPickUpTime ? intl.formatMessage({id: 'ROUND_TRIP'}) : intl.formatMessage({id: 'ONE_WAY'})}<br/>
            <b>
              {!booking.returnPickUpTime &&
                <FormattedMessage id="DATES_AND_TIMES"/>
              }
              {booking.returnPickUpTime &&
                <FormattedMessage id="ONE_WAY_DATES_AND_TIMES"/>
              }
              :</b> {booking.pickUpTime}<br/>
            {booking.returnPickUpTime &&
              <span><b><FormattedMessage id="RETURN_DATES_AND_TIMES"/>:</b> {booking.returnPickUpTime}<br/></span>
            }
            <b><FormattedMessage id="TRAVEL_TIME"/>:</b> {booking.durationMinutes} minutes<br/>
            <b><FormattedMessage id="DISTANCE"/>:</b> {booking.distanceKm} km ({booking.distanceMiles} mi)<br/>
            {booking.travelNumber && <span><b><FormattedMessage id="FLIGHT_OR_TRAIN_NUMBER"/>:</b> {booking.travelNumber}<br/></span>}
            {booking.notes && <span><b><FormattedMessage id="ORDER_NOTES"/>:</b> {booking.notes}<br/></span>}
            {booking.options && booking.options.map(o => <span><b>{o.name}:</b> {o.quantity}<br/></span>)}
            <br/>
            <h3 className="text-center mb-0" style={booking.discount ? {
              textDecoration: 'line-through',
              textDecorationThickness: '2px',
              color: '#4A5073',
              opacity: 0.75
            } : {color: '#4A5073'}}>{formatter.format(booking.price)}</h3>
            {booking.discount && <h3 className="text-center mb-0" style={{color: '#4A5073'}}>{formatter.format(booking.discount.discountPrice)}</h3>}
            {booking.discount && <p className="text-center mb-0">
              <FormattedMessage id="DISCOUNT_CODE_USED"/>: <b>{booking.discount.code}</b> (
              {booking.discount.percentageDiscount && <span>{booking.discount.percentageDiscount} %</span>}
              {(booking.discount.percentageDiscount && booking.discount.fixedDiscount) && <span> - </span>}
              {booking.discount.fixedDiscount && <span>{formatter.format(booking.discount.fixedDiscount)}</span>})</p>}
          </div>}
        </Tab.Pane>
        <Tab.Pane eventKey="customer" className="py-4">
          {booking && <div>
            <b><FormattedMessage id="FIRST_NAME"/>:</b> {booking.customer.firstName}<br/>
            <b><FormattedMessage id="LAST_NAME"/>:</b> {booking.customer.lastName}<br/>
            {booking.customer.companyName && <span><b><FormattedMessage id="COMPANY_NAME"/>:</b> {booking.customer.companyName}<br/></span>}
            <b><FormattedMessage id="EMAIL_ADDRESS"/>:</b> <a href={'mailto:' + booking.customer.email}>{booking.customer.email}</a><br/>
            <b><FormattedMessage id="PHONE"/>:</b> <a href={'tel:' + booking.customer.phone}>{booking.customer.phone}</a><br/>
            <b><FormattedMessage id="ADDRESS"/>:</b> {getFormattedCustomerAddress(booking.customer)}<br/>
          </div>}
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  }

  const renderBookingDetailsConfirmButton = () => {
    let booking;
    if (bookings) {
      booking = bookings.find(b => b.id === currentBookingId);
    }

    if (booking && booking.status === 'VALIDATED') {
      return <Button variant="success" onClick={() => {
        setShowDetailsModal(false);
        handleConfirmBookingButton(currentBookingId);
      }
      }>
        <FormattedMessage id="CONFIRM_BOOKING"/>
      </Button>
    }

    return <></>
  }

  return (
    <div>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pb-0" style={{minHeight: '400px'}}>
          <Row>
            <Col sm={6}>
              <h4 className="mt-2"><FormattedMessage id="BOOKINGS"/></h4>
            </Col>
            <Col sm={6}>
              <div className="d-flex justify-content-end mb-3">
                <Dropdown>
                  <Dropdown.Toggle as={Button} variant="primary">
                    <FontAwesomeIcon icon={faClipboard} className="me-2"/> <FormattedMessage id="REPORTS"/>
                    <span className="icon icon-small ms-1"><FontAwesomeIcon icon={faChevronDown}/></span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dashboard-dropdown custom-export-dropdown mt-1">
                    {/*<Dropdown.Item>*/}
                    {/*  <FontAwesomeIcon icon={faFilePdf} className="text-danger me-2"/> PDF*/}
                    {/*</Dropdown.Item>*/}
                    <Dropdown.Item onSelect={handleCsvReport}>
                      <FontAwesomeIcon icon={faFileCsv} className="text-success me-2"/> CSV
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Col>
          </Row>
          <Table hover className="user-table align-items-center">
            <thead className="thead-light">
            <tr>
              <th className="border-0 text-center">#</th>
              <th className="border-0 text-center"><FormattedMessage id="CUSTOMER"/></th>
              <th className="border-0 text-center"><FormattedMessage id="VEHICLE"/></th>
              <th className="border-0 text-center"><FormattedMessage id="PICKUP_DATETIME"/></th>
              <th className="border-0 text-center"><FormattedMessage id="AMOUNT"/></th>
              <th className="border-0 text-center"><FormattedMessage id="STATUS"/></th>
              <th className="border-0 text-center"><FormattedMessage id="PAYMENT"/></th>
              <th className="border-0 text-center"><FormattedMessage id="ORDER_DATE"/></th>
              <th className="border-0 text-center"><FormattedMessage id="ACTIONS"/></th>
            </tr>
            </thead>
            <tbody>
            {bookings && bookings.map(b => <TableRow key={`booking-${b.id}`} {...b} />)}
            </tbody>
          </Table>
          <Pagination size="md" className="mt-3" style={{float: 'right'}}>
            <Pagination.Prev onClick={onPrevItem}>
              <FontAwesomeIcon icon={faAngleDoubleLeft}/>
            </Pagination.Prev>
            {items}
            <Pagination.Next onClick={() => onNextItem(totalPages)}>
              <FontAwesomeIcon icon={faAngleDoubleRight}/>
            </Pagination.Next>
          </Pagination>
        </Card.Body>
        <Modal as={Modal.Dialog} centered show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <Modal.Header>
            <Modal.Title className="h6"><FormattedMessage id="DELETE_BOOKING"/></Modal.Title>
            <Button variant="close" aria-label="Close" onClick={() => setShowDeleteModal(false)}/>
          </Modal.Header>
          <Modal.Body>
            <FormattedMessage id="NO_LONGER_SEE_THIS_BOOKING"/>
            <br/>
            <FormattedMessage id="CUSTOMER_NOT_NOTIFIED_BY_EMAIL"/>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleDeleteBooking}>
              <FormattedMessage id="DELETE"/>
            </Button>
            <Button variant="link" className="text-gray ms-auto" onClick={() => setShowDeleteModal(false)}>
              <FormattedMessage id="CLOSE"/>
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal as={Modal.Dialog} centered show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
          <Modal.Header>
            <Modal.Title className="h6"><FormattedMessage id="CONFIRM_BOOKING"/></Modal.Title>
            <Button variant="close" aria-label="Close" onClick={() => setShowConfirmModal(false)}/>
          </Modal.Header>
          <Modal.Body>
            <FormattedMessage id="YOU_CAN_ATTACH_MSG_TO_CUSTOMER"/>
            <br/>
            <br/>
            <Form.Control as="textarea" rows="3" id="confirmMessage"
                          onChange={(e) => setConfirmMessage(e.target.value)} value={confirmMessage}/>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleConfirmBooking}>
              <FormattedMessage id="CONFIRM_BOOKING"/>
            </Button>
            <Button variant="link" className="text-gray ms-auto" onClick={() => setShowConfirmModal(false)}>
              <FormattedMessage id="CLOSE"/>
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal as={Modal.Dialog} centered show={showCancelModal} onHide={() => setShowCancelModal(false)}>
          <Modal.Header>
            <Modal.Title className="h6"><FormattedMessage id="CANCEL_BOOKING"/></Modal.Title>
            <Button variant="close" aria-label="Close" onClick={() => setShowCancelModal(false)}/>
          </Modal.Header>
          <Modal.Body>
            <FormattedMessage id="YOU_CAN_ATTACH_MSG_TO_CUSTOMER"/>
            <br/>
            <br/>
            <Form.Control as="textarea" rows="3" id="cancelMessage"
                          onChange={(e) => setCancelMessage(e.target.value)} value={cancelMessage}/>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="warning" onClick={handleCancelBooking}>
              <FormattedMessage id="CANCEL_BOOKING"/>
            </Button>
            <Button variant="link" className="text-gray ms-auto" onClick={() => setShowCancelModal(false)}>
              <FormattedMessage id="CLOSE"/>
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal as={Modal.Dialog} centered show={showDetailsModal} onHide={() => setShowDetailsModal(false)}>
          <Modal.Header>
            <Modal.Title className="h6"><FormattedMessage id="BOOKING_DETAILS"/></Modal.Title>
            <Button variant="close" aria-label="Close" onClick={() => setShowDetailsModal(false)}/>
          </Modal.Header>
          <Modal.Body>
            {renderBookingDetails()}
          </Modal.Body>
          <Modal.Footer>
            {renderBookingDetailsConfirmButton()}
            <Button variant="info" className="ms-auto" onClick={() => {
              setShowDetailsModal(false);
              setShowShareModal(true);
            }}>
              <FormattedMessage id="SHARE"/>
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal as={Modal.Dialog} centered show={showShareModal} onHide={() => setShowShareModal(false)}>
          <Modal.Header>
            <Modal.Title className="h6"><FormattedMessage id="SHARE_WITH_COLLEAGUE"/></Modal.Title>
            <Button variant="close" aria-label="Close" onClick={() => setShowShareModal(false)}/>
          </Modal.Header>
          <Modal.Body>
            <Form id="share-form" onSubmit={handleShareBooking}>
              <Form.Group id="email" className="mb-4">
                <Form.Label><FormattedMessage id="EMAIL_ADDRESS"/></Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faEnvelope}/>
                  </InputGroup.Text>
                  <Form.Control autoFocus required id="email"
                                type="email"
                                onChange={handleChange}
                                value={shareFormData.email}/>
                </InputGroup>
              </Form.Group>
              <FormattedMessage id="YOU_CAN_ATTACH_MSG_TO_RECIPIENT"/>
              <br/>
              <Form.Control as="textarea" rows="3" id="message"
                            onChange={handleChange} value={shareFormData.message}/>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" type="submit" form="share-form">
              <FormattedMessage id="SEND"/>
            </Button>
            <Button variant="link" className="text-gray ms-auto" onClick={() => setShowShareModal(false)}>
              <FormattedMessage id="CLOSE"/>
            </Button>
          </Modal.Footer>
        </Modal>
      </Card>
    </div>
  );
}
;
