import {Alert, Button, Card, Col, Form, InputGroup, Modal, OverlayTrigger, Row, Tooltip} from '@themesberg/react-bootstrap';
import React, {useEffect, useReducer} from 'react';
import Utils from '../services/Utils';
import {useHistory} from 'react-router-dom';
import {API_URL} from '../services/constants';
import {FormattedMessage} from 'react-intl';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowsLeftRight, faArrowsUpDown, faGlobe, faMagnifyingGlass, faMap, faTrashCan} from '@fortawesome/free-solid-svg-icons';
import {CompactPicker} from 'react-color';
import API from '../services/API';
import {faGoogle} from '@fortawesome/free-brands-svg-icons';
import Error from '../services/Error';
import AccordionComponent from '../components/AccordionComponent';


export default (props) => {

  const focus = ['focus'];
  const hover = ['hover'];
  const [identifier, setIdentifier] = React.useState('');
  const [htmlCode, setHtmlCode] = React.useState('');

  const formReducer = (state, event) => {
    if (event.name || event.value) {
      return {
        ...state,
        [event.name]: typeof event.value === 'boolean' ? event.value : event.value.replace('#', '')
      }
    } else {

      // Set default values
      if (!event.moduleWidth) {
        event.moduleWidth = 600;
      }

      if (!event.moduleHeight) {
        event.moduleHeight = 950;
      }

      if (!event.iconColor) {
        event.iconColor = '80BDFF';
      }

      if (!event.buttonColor) {
        event.buttonColor = '007BFF';
      }

      if (!event.centerCoords) {
        event.centerCoords = '46.41,2.05/5';
      }

      if (!event.redirectionUrl) {
        event.redirectionUrl = '';
      }

      if (event.identifier) {
        setIdentifier(event.identifier);
        setHtmlCode('<iframe src=\'' + API_URL + '/module/' + event.identifier + '\' width="' + event.moduleWidth + '" height="' + event.moduleHeight + '"></iframe>');
      }

      return event;
    }
  }

  const {intl} = props;
  const history = useHistory();
  const [refreshIframeKey, setRefreshIframeKey] = React.useState(0);
  const [formData, setFormData] = useReducer(formReducer, {});
  const [moduleWidth, setModuleWidth] = React.useState(0); // Only for alert msg
  const [moduleHeight, setModuleHeight] = React.useState(0); // Only for alert msg
  const [showMap, setShowMap] = React.useState(0); // Only for alert msg
  const [apiModal, setApiModal] = React.useState({
    showModal: false,
    placesAPI: true,
    warning: false
  });

  useEffect(() => {
    if (!Utils.isAccessToken(history)) {
      return;
    }

    API.getModule(history).then(response => {
      if (response && response.data.success === true) {
        setFormData(response.data.data);
        setModuleWidth(response.data.data.moduleWidth);
        setModuleHeight(response.data.data.moduleHeight);
        setShowMap(response.data.data.checkboxMap);
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }, [history]);

  const handleChange = event => {
    const isCheckbox = event.target.type === 'checkbox';
    setFormData({
      name: event.target.id,
      value: isCheckbox ? event.target.checked : event.target.value
    })
  }

  const handleChangeColor = (id, color) => {
    setFormData({
      name: id,
      value: color.hex
    })
  }

  const handleChangeCoordinates = (e) => {
    const coordsAndZoom = formData.centerCoords.split('/');
    const coords = coordsAndZoom[0].split(',');
    const zoom = coordsAndZoom[1];

    switch (e.target.id) {
      case 'latitude':
        setFormData({
          name: 'centerCoords',
          value: e.target.value + ',' + coords[1] + '/' + zoom
        })
        break;
      case 'longitude':
        setFormData({
          name: 'centerCoords',
          value: coords[0] + ',' + e.target.value + '/' + zoom
        })
        break;
      case 'zoom':
        setFormData({
          name: 'centerCoords',
          value: coords[0] + ',' + coords[1] + '/' + e.target.value
        })
        break;
    }
  }

  const handleChangeImageUpload = event => {
    // 1MB max
    if (event.target.files[0].size >= 1000000) {
      Utils.notifyWarning(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'FILE_TOO_LARGE'}));
    } else {
      API.postImage(history, {
        file: event.target.files[0]
      }).then(response => {
        if (response && response.data.success === true) {
          Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'IMAGE_FORM_UPLOADED'}));
          setFormData({
            name: 'img',
            value: true
          });
          setRefreshIframeKey(prevKey => prevKey + 1);
        } else {
          Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
        }
      });
    }
  }

  const handleSave = (e) => {
    API.postModule(history, formData).then(response => {
      if (response && response.data.success === true) {
        Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'SAVED_CHANGES'}));
        setModuleWidth(formData.moduleWidth);
        setModuleHeight(formData.moduleHeight);
        setShowMap(formData.checkboxMap);
        // Double setter for force refresh iframe
        setHtmlCode('<iframe src=\'' + API_URL + '/module/' + identifier + '\' width="' + formData.moduleWidth + '" height="' + formData.moduleHeight + '"></iframe> ');
        setHtmlCode('<iframe src=\'' + API_URL + '/module/' + identifier + '\' width="' + formData.moduleWidth + '" height="' + formData.moduleHeight + '"></iframe>');

        if (response.data.data) {
          switch(response.data.data) {
            case Error.googleApiDisabled():
              Utils.notifyWarning(intl.formatMessage({id: 'WARNING'}), intl.formatMessage({id: 'WARNING_GOOGLE_API'}));
              setApiModal({
                showModal: true,
                placesAPI: false,
                warning: false
              });
              break;
            case Error.googleApiWarning():
              setApiModal({
                showModal: true,
                placesAPI: true,
                warning: true
              });
              break;
          }
        }
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  const getArrayCoords = (coords) => {
    if (coords) {
      const coordsAndZoom = coords.split('/');
      const split = coordsAndZoom[0].split(',');
      const arrayCoords = [];
      arrayCoords.push(split[0]);
      arrayCoords.push(split[1]);
      arrayCoords.push(coordsAndZoom[1]);
      return arrayCoords;
    }
    return [46.41, 2.05, 5];
  }

  const deleteImage = event => {
    API.deleteImage(history).then(response => {
      if (response && response.data.success === true) {
        Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'IMAGE_FORM_DELETED'}));
        setFormData({
          name: 'img',
          value: false
        });
        setRefreshIframeKey(prevKey => prevKey + 1);
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  return (
    <Row>
      <Col xs={12} xl={9}>
        <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0 td-center">
            <div key={refreshIframeKey} dangerouslySetInnerHTML={{__html: htmlCode}}/>
          </Card.Body>
        </Card>
      </Col>
      <Col xs={12} xl={3}>
        <Row>
          <Col xs={12}>
            {moduleWidth < 250 &&
              <Alert variant="warning">
                <FormattedMessage id="ALERT_MODULE_WIDTH"/>
              </Alert>
            }
            {(!showMap && moduleHeight < 450) &&
              <Alert variant="warning">
                <FormattedMessage id="ALERT_MODULE_HEIGHT"/>
              </Alert>
            }
            {formData.vehicle === false &&
              <Alert variant="danger">
                <FormattedMessage id="NO_VEHICLES_CONFIGURED"/>
              </Alert>
            }
            {formData.payment === false &&
              <Alert variant="danger">
                <FormattedMessage id="NO_PAYMENT_CONFIGURED"/>
              </Alert>
            }
            {(formData.vehicle === true && formData.payment === true) &&
              <div>
                <AccordionComponent
                  defaultKey="panel-1"z
                  data={[
                    {
                      id: 1,
                      eventKey: 'panel-1',
                      title: <FormattedMessage id="HTML_INTEGRATION"/>,
                      content: <div className="td-center"><h5><FormattedMessage id="HTML_CODE_TO_INSERT"/></h5>
                        <Row className="d-flex align-items-center">
                          <Col className="mb-2 col-html-code-1" md={8}>
                            <Form.Group>
                              <Form.Control type="textarea" value={htmlCode} disabled/>
                            </Form.Group>
                          </Col>
                          <Col className="mb-2 col-html-code-2" md={4}>
                            <Button className="btn-copy" variant="light"
                                    onClick={() => {
                                      navigator.clipboard.writeText(htmlCode);
                                      Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'CODE_COPIED'}));
                                    }}>
                              <FormattedMessage id="COPY"/>
                            </Button>
                          </Col>
                        </Row>
                        {(formData.directUrl && formData.payment === true) &&
                          <div>
                            <h5 style={{marginTop: '7px'}}><b><FormattedMessage id="OR"/></b></h5>
                            <h5><FormattedMessage id="DIRECT_LINK_MODULE"/></h5>
                            <Card.Link style={{color: '#4A5073', fontWeight: 600}} href={formData.directUrl} target="_blank">
                              <u>{formData.directUrl}</u>
                            </Card.Link>
                            <br/>
                            <Button className="btn-copy-link" variant="light"
                                    onClick={() => {
                                      navigator.clipboard.writeText(formData.directUrl);
                                      Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'DIRECT_LINK_COPIED'}));
                                    }}>
                              <FormattedMessage id="COPY"/>
                            </Button>
                          </div>
                        }</div>
                    },
                    {
                      id: 2,
                      eventKey: 'panel-2',
                      title: <FormattedMessage id="TITLE_AND_DESCRIPTION"/>,
                      content: <div>
                        <Form.Group id="title">
                          <Form.Label><FormattedMessage id="FORM_TITLE"/></Form.Label>
                          <OverlayTrigger trigger={focus}
                                          overlay={
                                            <Tooltip><FormattedMessage id="TOOLTIP_FORM_TITLE"/></Tooltip>
                                          }>
                            <Form.Control type="text" id="title"
                                          onChange={handleChange} value={formData.title}/>
                          </OverlayTrigger>
                        </Form.Group>
                        <br/>
                        <Form.Group id="description">
                          <Form.Label><FormattedMessage id="FORM_DESCRIPTION"/></Form.Label>
                          <OverlayTrigger trigger={focus}
                                          overlay={
                                            <Tooltip><FormattedMessage id="TOOLTIP_DESCRIPTION_FORM"/></Tooltip>
                                          }>
                            <Form.Control as="textarea" id="description" maxLength="10000"
                                          onChange={handleChange} value={formData.description}/>
                          </OverlayTrigger>
                        </Form.Group>
                        <br/>
                        <Form.Group id="imgUrl">
                          <Form.Label><FormattedMessage id="FORM_IMAGE"/></Form.Label>
                          <div style={{display: 'flex'}}>
                            <Form.Control type="file" onChange={handleChangeImageUpload} accept="image/*"/>
                            {formData.img &&
                              <OverlayTrigger trigger={hover}
                                              overlay={
                                                <Tooltip><FormattedMessage id="DELETE_CURRENT_IMAGE"/></Tooltip>
                                              }>
                                <Button variant="danger"
                                        onClick={deleteImage}>
                                  <FontAwesomeIcon icon={faTrashCan}/>
                                </Button>
                              </OverlayTrigger>
                            }
                          </div>
                        </Form.Group>
                      </div>
                    },
                    {
                      id: 3,
                      eventKey: 'panel-3',
                      title: <FormattedMessage id="DISPLAY"/>,
                      content: <div>
                        <Form.Check label={intl.formatMessage({id: 'SHOW_MAP'})} id="checkboxMap"
                                    onChange={handleChange}
                                    checked={formData.checkboxMap || false}/>
                        <OverlayTrigger trigger={hover}
                                        overlay={
                                          <Tooltip><FormattedMessage id="TOOLTIP_USER_CAN_CHOOSE_NB_PASSENGERS"/></Tooltip>
                                        }>
                          <Form.Check label={intl.formatMessage({id: 'USER_CAN_CHOOSE_NB_PASSENGERS'})} id="checkboxPassengers"
                                      onChange={handleChange}
                                      checked={formData.checkboxPassengers || false}/>
                        </OverlayTrigger>
                        <OverlayTrigger trigger={hover}
                                        overlay={
                                          <Tooltip><FormattedMessage id="TOOLTIP_USER_CAN_CHOOSE_VEHICLE_TYPE"/></Tooltip>
                                        }>
                          <Form.Check label={intl.formatMessage({id: 'USER_CAN_CHOOSE_VEHICLE_TYPE'})} id="checkboxVehicles"
                                      onChange={handleChange}
                                      checked={formData.checkboxVehicles || false}/>
                        </OverlayTrigger>
                        <OverlayTrigger trigger={hover}
                                        overlay={
                                          <Tooltip><FormattedMessage id="TOOLTIP_USER_CAN_CHOOSE_TRIP_TYPE"/></Tooltip>
                                        }>
                          <Form.Check label={intl.formatMessage({id: 'USER_CAN_CHOOSE_TRIP_TYPE'})} id="checkboxTrip"
                                      onChange={handleChange}
                                      checked={formData.checkboxTrip || false}/>
                        </OverlayTrigger>
                        <br/>
                        <Row>
                          <Form.Label><FormattedMessage id="MODULE_SIZE"/></Form.Label>
                          <Col sm={6} style={{paddingRight: '0.1rem'}}>
                            <Form.Group>
                              <InputGroup>
                                <InputGroup.Text>
                                  <FontAwesomeIcon icon={faArrowsLeftRight}/>
                                </InputGroup.Text>
                                <Form.Control required type="number" id="moduleWidth"
                                              onChange={handleChange} value={formData.moduleWidth}/>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                          <Col sm={6} style={{paddingLeft: '0.5rem'}}>
                            <Form.Group>
                              <InputGroup>
                                <InputGroup.Text>
                                  <FontAwesomeIcon icon={faArrowsUpDown}/>
                                </InputGroup.Text>
                                <Form.Control required type="number" id="moduleHeight"
                                              onChange={handleChange} value={formData.moduleHeight}/>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                    },
                    {
                      id: 4,
                      eventKey: 'panel-4',
                      title: 'Google Maps',
                      content: <div>
                        <Form.Label><FormattedMessage id="GOOGLE_MAPS_API"/></Form.Label>
                        <Form.Group>
                          <InputGroup>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faGoogle}/>
                            </InputGroup.Text>
                            <OverlayTrigger trigger={focus}
                                            overlay={
                                              <Tooltip><FormattedMessage id="TOOLTIP_GOOGLE_MAPS_API"/></Tooltip>
                                            }>
                              <Form.Control required type="text" id="googleMapsApi"
                                            onChange={handleChange} value={formData.googleMapsApi}/>
                            </OverlayTrigger>
                          </InputGroup>
                        </Form.Group>
                      </div>
                    },
                    {
                      id: 5,
                      eventKey: 'panel-5',
                      title: <FormattedMessage id="CENTER_COORDS"/>,
                      content: <div>
                        <Row>
                          <Form.Label><FormattedMessage id="MAP_CENTER_COORDS"/></Form.Label>
                          <Col sm={6} style={{paddingRight: '0.1rem'}}>
                            <Form.Group>
                              <InputGroup>
                                <InputGroup.Text>
                                  <FontAwesomeIcon icon={faMap}/>
                                </InputGroup.Text>
                                <OverlayTrigger trigger={focus}
                                                overlay={
                                                  <Tooltip><FormattedMessage id="LATITUDE"/></Tooltip>
                                                }>
                                  <Form.Control required type="number" id="latitude" step="any"
                                                onChange={handleChangeCoordinates} value={getArrayCoords(formData.centerCoords)[0]}/>
                                </OverlayTrigger>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                          <Col sm={6} style={{paddingLeft: '0.5rem'}}>
                            <Form.Group>
                              <InputGroup>
                                <InputGroup.Text>
                                  <FontAwesomeIcon icon={faMap}/>
                                </InputGroup.Text>
                                <OverlayTrigger trigger={focus}
                                                overlay={
                                                  <Tooltip><FormattedMessage id="LONGITUDE"/></Tooltip>
                                                }>
                                  <Form.Control required type="number" id="longitude" step="any"
                                                onChange={handleChangeCoordinates} value={getArrayCoords(formData.centerCoords)[1]}/>
                                </OverlayTrigger>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                        </Row>
                        <br/>
                        <Form.Group>
                          <InputGroup>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faMagnifyingGlass}/>
                            </InputGroup.Text>
                            <OverlayTrigger trigger={focus}
                                            overlay={
                                              <Tooltip><FormattedMessage id="ZOOM_LEVEL"/></Tooltip>
                                            }>
                              <Form.Control required type="number" id="zoom"
                                            onChange={handleChangeCoordinates} value={getArrayCoords(formData.centerCoords)[2]}/>
                            </OverlayTrigger>
                          </InputGroup>
                        </Form.Group>
                      </div>,
                      show: formData.checkboxMap
                    },
                    {
                      id: 6,
                      eventKey: 'panel-6',
                      title: <FormattedMessage id="REDIRECTION"/>,
                      content: <Row>
                        <Form.Label><FormattedMessage id="REDIRECTION_URL_BOOKING"/></Form.Label>
                        <Col sm={12} style={{paddingRight: '0.1rem'}}>
                          <Form.Group>
                            <InputGroup>
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faGlobe}/>
                              </InputGroup.Text>
                              <OverlayTrigger trigger={focus}
                                              overlay={
                                                <Tooltip><FormattedMessage id="TOOLTIP_REDIRECTION_URL"/></Tooltip>
                                              }>
                                <Form.Control required type="text" id="redirectionUrl"
                                              onChange={handleChange} value={formData.redirectionUrl}/>
                              </OverlayTrigger>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>
                    },
                    {
                      id: 7,
                      eventKey: 'panel-7',
                      title: <FormattedMessage id="COLORS"/>,
                      content: <div>
                        <Form.Label><FormattedMessage id="ICONS_COLOR"/></Form.Label>
                        <br/>
                        <CompactPicker id="iconColor" color={formData.iconColor}
                                       onChangeComplete={(color) => handleChangeColor('iconColor', color)}/>
                        <br/><br/>
                        <Form.Label><FormattedMessage id="BUTTON_COLOR"/></Form.Label>
                        <br/>
                        <CompactPicker id="buttonColor" color={formData.buttonColor}
                                       onChangeComplete={(color) => handleChangeColor('buttonColor', color)}/>
                      </div>
                    }
                  ]}/>
              </div>
            }
          </Col>
          <Col xs={12}>
            <div className="td-center">
              <br/>
              <Button variant="primary" onClick={handleSave}>
                <FormattedMessage id="RECORD"/>
              </Button>
            </div>
          </Col>
        </Row>
      </Col>
      <Modal as={Modal.Dialog} centered show={apiModal.showModal} onHide={() => setApiModal({
        showModal: false,
        placesAPI: true,
        warning: false
      })}>
        <Modal.Header>
          <Modal.Title className="h6"><FormattedMessage id="GOOGLE_MAPS_API"/></Modal.Title>
          <Button variant="close" aria-label="Close" onClick={() => setApiModal({
            showModal: false,
            placesAPI: true,
            warning: false
          })}/>
        </Modal.Header>
        <Modal.Body>
          {!apiModal.placesAPI && <FormattedMessage id="GOOGLE_API_DISABLED"/>}
          {apiModal.warning && <FormattedMessage id="GOOGLE_API_CHECK"/>}
          <br/><br/>
          <ul>
            <li><Card.Link href="https://console.cloud.google.com/marketplace/product/google/maps-backend.googleapis.com" target="_blank"><b>Maps JavaScript API</b></Card.Link></li>
            <li><Card.Link href="https://console.cloud.google.com/apis/library/directions-backend.googleapis.com" target="_blank"><b>Directions API</b></Card.Link></li>
            <li><Card.Link href="https://console.cloud.google.com/apis/library/places-backend.googleapis.com" target="_blank"><b>Places API</b></Card.Link></li>
            <li><Card.Link href="https://console.cloud.google.com/apis/library/places.googleapis.com" target="_blank"><b>Places API (New)</b></Card.Link></li>
          </ul>
          {!apiModal.placesAPI && <div><a href="https://console.cloud.google.com/apis/credentials" target="_blank"><FormattedMessage id="CLICK_HERE"/></a><FormattedMessage id="ADD_RESTRICTION_API"/></div>}
          <br/>
          {!apiModal.placesAPI && <div><FormattedMessage id="CHECK_API_KEY"/><br/><i>{formData.googleMapsApi}</i></div>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" className="text-gray ms-auto" onClick={() => setApiModal({
            showModal: false,
            placesAPI: true,
            warning: false
          })}>
            <FormattedMessage id="CLOSE"/>
          </Button>
        </Modal.Footer>
      </Modal>
    </Row>
  );
};
